import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import HeroSection from '../components/home/hero'
import PortfolioSection from '../components/home/portfolio'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  }
}));

const IndexPage = () => {

  const classes = useStyles()
  const [features, setFeatures] = React.useState(true)
  const [info, setInfo] = React.useState(true)

  function handleClick(id) {
    switch(id) {
      case "features":
        setFeatures(!features)
        break;
      case "info":
        setInfo(!info)
        break;
      // no default
    }
  }

  return(
    <Layout>
      <SEO title="Home" />  
      <HeroSection className="hero-container" />
      <div class="wwd-container" style={{
        backgroundColor: `#fff`,
        padding: `30px`
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={3} justify="center">
            <Grid item xs={12}>
              <h2>What we do</h2>
            </Grid>
            <Grid item xs={4}>
              Data Visualization
            </Grid>
            <Grid item xs={4}>
              Data Driven Web Application Development
            </Grid>
            <Grid item xs={4}>
              Data Training
            </Grid>
          </Grid>
        </Container>
      </div>
      <PortfolioSection className="portfolio-container" />
      <div class="team-container" style={{
        backgroundColor: `#fff`,
        padding: `30px`
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={3} justify="center">
            <Grid item xs={12}>
              <h2>Meet the Team</h2>
            </Grid>
            <Grid item xs={2}>
              <img src="https://picsum.photos/id/237/200" width='100%'/>
              <img src="https://picsum.photos/id/1025/200" width='100%'/>
            </Grid>
            <Grid item xs={2} style={{
              marginTop: `40px`
            }}>
              <img src="https://picsum.photos/id/1062/200" width='100%'/>
              <img src="https://picsum.photos/id/1074/200" width='100%'/>
            </Grid>
            <Grid item xs={2}>
              <img src="https://picsum.photos/id/219/200" width='100%'/>
              <img src="https://picsum.photos/id/200/200" width='100%'/>
            </Grid>
            <Grid item xs={2} style={{
              marginTop: `40px`
            }}>
              <img src="https://picsum.photos/id/433/200" width='100%'/>
              <img src="https://picsum.photos/id/582/200" width='100%'/>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div class="contact-container" style={{
        backgroundColor: `#000`,
        color: `#fff`,
        padding: `30px`,
        textAlign: `center`
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={3} justify="center">
            <Grid item xs={12}>
              <h2>Want to know more about us?</h2>
              <p>Shoot an email to us</p>
              <a href="#" style={{
                color: `#F1673A`,
                fontSize: `2rem`
              }}>info@thibi.co</a>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Layout>
  )
}

export default IndexPage
