import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'

import BackgroundImage from 'gatsby-background-image'

const PortfolioSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "thiibi-background-2.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="div"
          className={className}
          fluid={imageData}
          style={{
            backgroundColor:`#F1673A`,
            color: `#fff`,
            padding: `30px`
          }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={3} justify="center">
              <Grid item xs={12}>
                <h2>What we have done</h2>
              </Grid>
              <Grid item xs={7}>
                <a href="https://myonaegis.com/"><img src="https://picsum.photos/id/6/600/400" width='100%'/></a>
              </Grid>
              <Grid item xs={5} style={{
                paddingLeft: `5%`
              }}>
                <h5 style={{
                  marginBottom: `0.6rem`,
                  color: `#000`
                }}>
                  INTERACTIVE MAPPING FOR DECISION MAKING
                </h5>
                <h3>Township GIS</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>WEB APP DEVELOPMENT, DATA VISUALIZATION, INTERACTIVE MAP</p>
              </Grid>
              <Grid item xs={5} style={{
                paddingRight: `5%`,
                textAlign: `right`
              }}>
                <h3>Myanmar Jade Portal</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>WEB APP DEVELOPMENT, DATA VISUALIZATION, INTERACTIVE MAP</p>
              </Grid>
              <Grid item xs={7}>
                <a href="#"><img src="https://picsum.photos/id/60/600/400" width='100%'/></a>
              </Grid>
              <Grid item xs={7}>
                <img src="https://picsum.photos/id/625/600/400" width='100%'/>
              </Grid>
              <Grid item xs={5} style={{
                paddingLeft: `5%`
              }}>
                <h3>Cambodia Data Training</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>DATA TRAINING</p>
              </Grid>
            </Grid>
          </Container>
        </BackgroundImage>
      )
    }}
  />
)

export default PortfolioSection