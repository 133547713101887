import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'

import BackgroundImage from 'gatsby-background-image'

const HeroSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "thibi-background.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="div"
          className={className}
          fluid={imageData}
          style={{
            backgroundColor: `#000000`,
            color: `#fff`,
            padding: `30px`,
            paddingTop: 120
          }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={3} justify="center">
              <Grid item xs={12} style={{
                textAlign: `center`,
                padding: `100px 200px`,
              }}>
                  <h1>We design data that makes us <br/> more human, not less.</h1>
                  <p style={{
                    color: `#F1673A`,
                    lineHeight: `1.5rem`
                  }}>
                    Our purpose is to design data-driven technologies that allow people to intuitively understand complexity and augments the human ability to discover insights for positive social impact.
                  </p>
              </Grid>
            </Grid>
          </Container>
        </BackgroundImage>
      )
    }}
  />
)

export default HeroSection